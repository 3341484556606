exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._22akEvF5pDqARopEwDbV8o{border-radius:5px !important;box-shadow:0 0 5px 1px rgba(0,0,0,.5);padding:1em 2em}._2_pZELwJIU-5iAsR-0urGI{margin:.5em;color:#fff}._3dPKACNm5uFMsxWub9_vN5{text-align:center;color:#fff;font-weight:600}._3jAKUbjyJdg4esbJ2f_cZU{color:#fff;text-transform:uppercase;font-weight:600;text-align:center}._2HI11xEnBbd7kqiUmvKJVu{word-break:break-word}.ajRwxn7rf4lV-64d87B0k{display:flex;justify-content:center}._35ex4TOVUdjsWjh-ppsbAt{background:#c1d030;color:#071a25;box-shadow:0 2px 0 #071a25;text-transform:uppercase;font-weight:bold;padding:8px 0;text-align:center;border-radius:4px !important;border:0;cursor:pointer;width:100%;box-shadow:2px 2px 1px #071a25;padding:1em 0;margin:8px 0;transition:box-shadow 350ms linear}@media(min-width: 769px){._35ex4TOVUdjsWjh-ppsbAt{max-width:300px;width:300px}}._35ex4TOVUdjsWjh-ppsbAt:hover{box-shadow:none}", ""]);

// Exports
exports.locals = {
	"gameRulesWrapper": "_22akEvF5pDqARopEwDbV8o",
	"gameRulesInfoTxt": "_2_pZELwJIU-5iAsR-0urGI",
	"gameRulesTxt": "_3dPKACNm5uFMsxWub9_vN5",
	"gameRulesHead": "_3jAKUbjyJdg4esbJ2f_cZU",
	"gameRulesBreakWord": "_2HI11xEnBbd7kqiUmvKJVu",
	"gameRulesBtnWrapper": "ajRwxn7rf4lV-64d87B0k",
	"gameRulesBtn": "_35ex4TOVUdjsWjh-ppsbAt"
};