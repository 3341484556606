exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2uoh5VUK1HuCTDoSdqIN61{border-radius:5px !important;padding:1.5em 1em;padding-top:3em;max-width:340px;position:relative}@media(min-width: 769px){._2uoh5VUK1HuCTDoSdqIN61{padding:3em 2em;max-width:460px}}._2uoh5VUK1HuCTDoSdqIN61 ._1Sw5BBY_dAp3Ri3i10teAn{position:absolute;right:.75em;top:.5em;font-size:2em;font-family:\"Icons\";color:#ababab;cursor:pointer}._2uoh5VUK1HuCTDoSdqIN61 ._6EjBudg0smoG18xV_S1D8{color:#fff;font-weight:bold;font-size:.9em;margin-bottom:1em;text-align:center}@media(min-width: 769px){._2uoh5VUK1HuCTDoSdqIN61 ._6EjBudg0smoG18xV_S1D8{font-size:1em}}._2uoh5VUK1HuCTDoSdqIN61 ._3uusNuDG2j-Dj5BMBflAKD{display:flex;justify-content:space-around}._2uoh5VUK1HuCTDoSdqIN61 .xLY114vQMUHQgOwr8DyWE{border-radius:4px !important;padding:.4em .5em;font-weight:bold;cursor:pointer}@media(min-width: 769px){._2uoh5VUK1HuCTDoSdqIN61 .xLY114vQMUHQgOwr8DyWE{padding:.75em 1.5em}}._2uoh5VUK1HuCTDoSdqIN61 ._2VZvmhb65d4EyctqcW1gge{color:#fff;background:#009a44;box-shadow:0 2px 0 #006b15}._2uoh5VUK1HuCTDoSdqIN61 .OIgUThgecXbhaf4io6_UU{color:#fff;background:#979797;box-shadow:0 2px 0 #5a5a5a}", ""]);

// Exports
exports.locals = {
	"modalWrapper": "_2uoh5VUK1HuCTDoSdqIN61",
	"closeIcon": "_1Sw5BBY_dAp3Ri3i10teAn",
	"modalTxt": "_6EjBudg0smoG18xV_S1D8",
	"btnWrapper": "_3uusNuDG2j-Dj5BMBflAKD",
	"modalBtn": "xLY114vQMUHQgOwr8DyWE",
	"confirmBtn": "_2VZvmhb65d4EyctqcW1gge",
	"cancelBtn": "OIgUThgecXbhaf4io6_UU"
};