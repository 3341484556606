exports = module.exports = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3Uy4ZJjXGY4dvsCVLkx9wO{padding:10px 5px;display:flex;justify-content:center;align-items:center;flex-direction:column}._2PrEmg9BwuGegMVyXWZMyF{width:100%;display:flex;flex-wrap:wrap;justify-content:center}._1pzphBqe0qQM5p5Ab27Hy0{color:#afafaf;font-weight:bold;font-size:14px;margin:0;margin-bottom:5px}.imGiYe8v-cgQMEGC-9n4Y{color:#fff;border:1px solid #c1d030;background:#071a25;box-shadow:0 2px 0 #c1d030;font-weight:bold;padding:3px;margin:5px;border-radius:4px !important;cursor:pointer;white-space:nowrap;text-align:center;flex-basis:calc(33% - 30px);flex-grow:1;font-size:15px}.fcpQSXf2KWn9YNGXYdM3B{color:#071a25;background:#c1d030}", ""]);

// Exports
exports.locals = {
	"stakeContainer": "_3Uy4ZJjXGY4dvsCVLkx9wO",
	"stakePicker": "_2PrEmg9BwuGegMVyXWZMyF",
	"stakePickHead": "_1pzphBqe0qQM5p5Ab27Hy0",
	"stakePickBtn": "imGiYe8v-cgQMEGC-9n4Y",
	"stakePickBtn_active": "fcpQSXf2KWn9YNGXYdM3B"
};